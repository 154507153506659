<div class="flex items-center gap-4">
  <app-icon-league [leaguePoints]="leaguePoints()" size="lg" />
  @if (currentLeague(); as currentLeague) {
    <div class="flex flex-col">
      <span class="text-sm font-semibold text-github-muted-foreground">{{ currentLeague.name }}</span>
      <!-- Points display -->
      <div class="flex justify-between text-sm text-github-muted-foreground">
        <div class="flex items-center gap-2">
          @if (currentLeague.maxPoints === Infinity) {
            <span>{{ leaguePoints() }}</span>
          } @else {
            <span>{{ leaguePoints() }} / {{ currentLeague.maxPoints }}</span>
          }
          <lucide-angular [img]="Star" class="size-4" />
        </div>
      </div>
      <!-- Progress bar container -->
      <div class="flex justify-center items-center gap-2">
        <brn-progress class="w-24 bg-secondary h-2 inline-flex overflow-hidden relative rounded-md" aria-labelledby="loading" [value]="progressValue()">
          <brn-progress-indicator hlm [style.backgroundColor]="'hsl(var(--league-' + currentLeague.name.toLowerCase() + '))'" />
        </brn-progress>
        <app-icon-league [leaguePoints]="currentLeague.maxPoints + 1" size="sm" />
      </div>
    </div>
  }
</div>

<div class="flex items-start space-x-3">
  @if (isClosed()) {
    <div hlmAlert class="flex flex-row space-x-3">
      <lucide-angular [img]="Alert" class="size-4" />
      <div>
        <h4 hlmAlertTitle>Please continue messaging in your most recent session.</h4>
        <p hlmAlertDesc>To stay organized, starting a new session automatically closes the previous one. <br /></p>
      </div>
    </div>
  } @else {
    <textarea
      hlmInput
      [(ngModel)]="message"
      placeholder="Message AI Mentor"
      style="resize: none"
      (keydown.enter)="onSendMessage()"
      class="w-full min-h-20"
      [disabled]="isSending() || isClosed()"
    ></textarea>
  }
  <button hlmBtn [disabled]="isSending() || isClosed()" aria-describedby="Send Message" (click)="onSendMessage()" variant="default" size="icon">
    @if (isSending()) {
      <hlm-spinner size="sm" />
    } @else {
      <lucide-angular [img]="Send" class="size-5" />
    }
  </button>
</div>

<div class="flex items-center justify-center space-y-4">
  <div class="w-full grid grid-cols-1 gap-5 lg:grid-cols-4">
    @if (sessions.isPending() || (sessions.data()?.length ?? 0) > 0) {
      <app-sessions-card
        class="order-first lg:order-none"
        [sessions]="sessions.data()"
        [isLoading]="sessions.isPending()"
        [(selectedSessionId)]="selectedSessionId"
        (createNewSession)="this.createNewSession.mutate()"
      />
    }

    @if (!sessions.isPending() && (sessions.data()?.length ?? 0) === 0) {
      <div class="flex flex-col lg:col-span-4 my-32">
        <app-start-session-card (createNewSession)="this.createNewSession.mutate()" [isLoading]="sessions.isPending()" [hasSessions]="(sessions.data()?.length ?? 0) > 0" />
      </div>
    } @else {
      <div class="flex flex-col lg:col-span-3 lg:justify-center" [class.my-32]="(selectedSessionMessages.data()?.length ?? 0) === 0">
        @if (selectedSessionId() !== undefined) {
          @if ((selectedSessionMessages.data()?.length ?? 0) > 0 || selectedSessionMessages.isPending()) {
            <hlm-scroll-area #messagesScrollArea class="h-[calc(100dvh-200px)] lg:h-[calc(100dvh-300px)] -mr-2.5">
              <div class="pr-3">
                <app-messages [isLoading]="selectedSessionMessages.isPending()" [messages]="selectedSessionMessages.data() ?? []" class="p-4 space-y-4" />
              </div>
            </hlm-scroll-area>
          } @else {
            <h2 class="text-2xl text-center font-medium">How has your week been?</h2>
          }
          <app-chat-input
            [isSending]="sendMessage.isPending()"
            (sendMessage)="sendMessage.mutate({ sessionId: selectedSessionId()!, message: $event })"
            class="pt-4 px-4 sticky bg-background"
            [isClosed]="selectedSessionClosed()"
            [class.border-t]="selectedSessionMessages.data()?.length"
          />
        } @else {
          <app-start-session-card (createNewSession)="this.createNewSession.mutate()" [isLoading]="sessions.isPending()" [hasSessions]="(sessions.data()?.length ?? 0) > 0" />
        }
      </div>
    }
  </div>
</div>

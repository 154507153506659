<div class="flex items-center justify-between mx-8">
  <div class="flex gap-8 items-center">
    @if (isLoading()) {
      <hlm-avatar variant="extralarge" class="ring-2 ring-neutral-100 dark:ring-neutral-800">
        <hlm-skeleton hlmAvatarImage class="h-full w-full rounded-full"></hlm-skeleton>
        <hlm-skeleton hlmAvatarFallback class="h-full w-full rounded-full"></hlm-skeleton>
      </hlm-avatar>
    } @else {
      <hlm-avatar variant="extralarge" class="ring-2 ring-neutral-100 dark:ring-neutral-800">
        <img [src]="user()?.avatarUrl" [alt]="user()?.login + '\'s avatar'" hlmAvatarImage />
        <span hlmAvatarFallback>
          {{ user()?.login?.slice(0, 2)?.toUpperCase() }}
        </span>
      </hlm-avatar>
    }
    @if (isLoading()) {
      <div class="flex flex-col gap-2">
        <hlm-skeleton class="h-8 w-48" />
        <hlm-skeleton class="h-5 w-64" />
        <hlm-skeleton class="h-5 w-80" />
        <div class="flex items-center gap-2">
          <hlm-skeleton class="size-10" />
          <hlm-skeleton class="size-10" />
        </div>
      </div>
    }
    @if (!isLoading() && user(); as user) {
      <div class="flex flex-col gap-1">
        <h1 class="text-2xl md:text-3xl font-bold leading-6">{{ user.name }}</h1>
        <a class="md:text-lg font-medium text-muted-foreground mb-1 hover:text-github-accent-foreground" [href]="user.htmlUrl" target="_blank" rel="noopener noreferrer">
          github.com/{{ user.login }}
        </a>
        @if (displayFirstContribution()) {
          <div class="flex items-center gap-1 md:gap-2 text-muted-foreground font-medium text-sm md:text-base">
            <ng-icon [svg]="octClockFill" size="16" class="!overflow-visible" />
            Contributing since {{ displayFirstContribution() }}
          </div>
        }
        @if (contributedRepositories(); as contributedRepositories) {
          <div class="flex items-center gap-2">
            @for (repository of contributedRepositories; track repository) {
              <hlm-tooltip>
                <a hlmBtn hlmTooltipTrigger variant="outline" class="size-10 p-1" [aria-describedby]="repository.nameWithOwner" [href]="repository.htmlUrl">
                  <img [src]="getRepositoryImage(repository.nameWithOwner)" [alt]="repository" />
                </a>
                <span *brnTooltipContent>{{ repository.nameWithOwner }}</span>
              </hlm-tooltip>
            }
          </div>
        }
      </div>
    }
  </div>
  <div class="flex flex-col justify-center items-center gap-2">
    <app-icon-league [leaguePoints]="leaguePoints() ?? 0" size="max" />
    <span class="text-muted-foreground text-xl md:text-2xl font-bold leading-6">{{ leaguePoints() ?? 0 }}</span>
  </div>
</div>

<div hlmCard>
  <div hlmCardContent class="py-4">
    <span class="flex justify-between items-center pb-2 gap-2">
      <h3 class="text-base font-semibold tracking-wide">Your League</h3>
      <app-league-info-modal />
    </span>
    <div class="flex items-center gap-2">
      <app-league-elo-card [leaguePoints]="leaguePoints()" />
    </div>
  </div>
</div>

<header class="container flex items-center justify-between pt-4 gap-2">
  <div class="flex gap-4 items-center flex-1">
    <div class="flex items-center gap-2">
      <a class="flex gap-2 items-center hover:text-muted-foreground" routerLink="/">
        <lucide-angular [img]="Hammer" class="size-8 sm:size-6" />
        <span class="hidden sm:inline-block text-xl font-semibold">Hephaestus</span>
      </a>
      <span class="text-xs font-semibold mt-1 text-muted-foreground">{{ version }}</span>
    </div>
    @if (user()?.roles?.includes('admin')) {
      <a hlmBtn variant="link" routerLink="/workspace">Workspace</a>
    }
  </div>
  @if (user()?.roles?.includes('mentor_access')) {
    <app-ai-mentor class="hidden sm:inline-block" />
    <app-ai-mentor class="sm:hidden" iconOnly />
  }
  <app-request-feature class="hidden sm:inline-block" />
  <app-request-feature class="sm:hidden" iconOnly />
  <app-theme-switcher />
  @if (signedIn()) {
    <button [brnMenuTriggerFor]="usermenu" class="ml-2">
      <hlm-avatar>
        <img [src]="'https://github.com/' + user()!.username + '.png'" [alt]="user()?.name + '\'s avatar'" hlmAvatarImage />
        <span hlmAvatarFallback>
          {{ user()?.name?.slice(0, 2)?.toUpperCase() ?? '?' }}
        </span>
      </hlm-avatar>
    </button>
    <ng-template #usermenu>
      <hlm-menu>
        <hlm-menu-label>{{ user()!.name }}</hlm-menu-label>
        <hlm-menu-separator />
        <hlm-menu-group>
          <a hlmMenuItem [routerLink]="'/user/' + user()!.username" class="cursor-pointer">
            <hlm-icon name="lucideUser" hlmMenuIcon />
            <span>My Profile</span>
          </a>
          <a hlmMenuItem routerLink="/settings" class="cursor-pointer">
            <hlm-icon name="lucideSettings" hlmMenuIcon />
            <span>Settings</span>
          </a>
          <hlm-menu-separator />
          <button hlmMenuItem (click)="signOut()" class="cursor-pointer">
            <hlm-icon name="lucideLogOut" hlmMenuIcon />
            <span>Sign&nbsp;Out</span>
          </button>
        </hlm-menu-group>
      </hlm-menu>
    </ng-template>
  } @else {
    <button hlmBtn (click)="signIn()">Sign&nbsp;In</button>
  }
</header>

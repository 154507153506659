{
  "name": "hephaestus",
  "version": "0.0.1",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "storybook": "ng run webapp:storybook",
    "build-storybook": "ng run webapp:build-storybook",
    "chromatic": "npx chromatic --exit-zero-on-changes",
    "prettier:check": "prettier --check src/",
    "prettier:write": "prettier --write src/",
    "lint": "eslint",
    "lint:fix": "eslint --fix"
  },
  "private": true,
  "engines": {
    "node": ">=20.16 <23"
  },
  "dependencies": {
    "@angular/animations": "19.0.5",
    "@angular/cdk": "18.2.7",
    "@angular/common": "19.0.5",
    "@angular/compiler": "19.0.5",
    "@angular/core": "19.0.5",
    "@angular/forms": "19.0.5",
    "@angular/platform-browser": "19.0.5",
    "@angular/platform-browser-dynamic": "19.0.5",
    "@angular/router": "19.0.5",
    "@ng-icons/core": "^29.10.0",
    "@ng-icons/lucide": ">=29.0.0",
    "@ng-icons/octicons": "29.5.0",
    "@primer/primitives": "9.1.1",
    "@sentry/angular": "^8.42.0",
    "@spartan-ng/brain": "0.0.1-alpha.374",
    "@spartan-ng/ui-accordion-brain": "0.0.1-alpha.374",
    "@spartan-ng/ui-alertdialog-brain": "0.0.1-alpha.374",
    "@spartan-ng/ui-avatar-brain": "0.0.1-alpha.374",
    "@spartan-ng/ui-collapsible-brain": "0.0.1-alpha.374",
    "@spartan-ng/ui-command-brain": "0.0.1-alpha.374",
    "@spartan-ng/ui-core": "0.0.1-alpha.374",
    "@spartan-ng/ui-dialog-brain": "0.0.1-alpha.374",
    "@spartan-ng/ui-formfield-brain": "0.0.1-alpha.374",
    "@spartan-ng/ui-forms-brain": "0.0.1-alpha.374",
    "@spartan-ng/ui-hovercard-brain": "0.0.1-alpha.374",
    "@spartan-ng/ui-label-brain": "0.0.1-alpha.374",
    "@spartan-ng/ui-menu-brain": "0.0.1-alpha.374",
    "@spartan-ng/ui-popover-brain": "0.0.1-alpha.374",
    "@spartan-ng/ui-progress-brain": "0.0.1-alpha.374",
    "@spartan-ng/ui-radiogroup-brain": "0.0.1-alpha.374",
    "@spartan-ng/ui-select-brain": "0.0.1-alpha.374",
    "@spartan-ng/ui-separator-brain": "0.0.1-alpha.374",
    "@spartan-ng/ui-sheet-brain": "0.0.1-alpha.374",
    "@spartan-ng/ui-slider-brain": "0.0.1-alpha.374",
    "@spartan-ng/ui-switch-brain": "0.0.1-alpha.374",
    "@spartan-ng/ui-table-brain": "0.0.1-alpha.374",
    "@spartan-ng/ui-tabs-brain": "0.0.1-alpha.374",
    "@spartan-ng/ui-toggle-brain": "0.0.1-alpha.374",
    "@spartan-ng/ui-tooltip-brain": "0.0.1-alpha.374",
    "@tanstack/angular-query-devtools-experimental": "5.52.0",
    "@tanstack/angular-query-experimental": "5.52.0",
    "autoprefixer": "10.4.20",
    "class-variance-authority": "^0.7.0",
    "clsx": "2.1.1",
    "dayjs": "1.11.13",
    "embla-carousel-angular": "18.0.0",
    "keycloak-js": "^26.0.0",
    "lucide-angular": "^0.469.0",
    "ngx-scrollbar": "^13.0.3",
    "ngx-sonner": "^2.0.0",
    "postcss": "8.4.41",
    "rxjs": "7.8.1",
    "tailwind-merge": "2.5.2",
    "tailwindcss": "^3.4.17",
    "tailwindcss-animate": "1.0.7",
    "tslib": "2.6.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.0.6",
    "@angular-eslint/eslint-plugin": "18.3.0",
    "@angular-eslint/template-parser": "18.3.0",
    "@angular/cli": "19.0.6",
    "@angular/compiler-cli": "19.0.5",
    "@chromatic-com/storybook": "1.7.0",
    "@spartan-ng/cli": "^0.0.1-alpha.374",
    "@storybook/addon-essentials": "^8.4.7",
    "@storybook/addon-interactions": "^8.4.7",
    "@storybook/addon-links": "^8.4.7",
    "@storybook/addon-onboarding": "^8.4.7",
    "@storybook/addon-themes": "^8.4.7",
    "@storybook/angular": "^8.4.7",
    "@storybook/blocks": "^8.4.7",
    "@storybook/test": "^8.4.7",
    "@tailwindcss/typography": "0.5.15",
    "@types/jasmine": "5.1.4",
    "@typescript-eslint/eslint-plugin": "8.2.0",
    "@typescript-eslint/parser": "8.2.0",
    "chromatic": "^11.22.0",
    "eslint": "9.9.0",
    "eslint-plugin-prettier": "5.2.1",
    "jasmine-core": "5.2.0",
    "karma": "6.4.4",
    "karma-chrome-launcher": "3.2.0",
    "karma-coverage": "2.2.1",
    "karma-jasmine": "5.1.0",
    "karma-jasmine-html-reporter": "2.1.0",
    "prettier": "3.3.3",
    "storybook": "^8.4.7",
    "typescript": "5.5.4"
  }
}
